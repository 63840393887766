<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    style="background-color: blue; border-radius: 30px !important"
  >
    <v-card flat>
      <v-card-title class="secondary py-1" style="height: 40px; position: sticky; top: 0; z-index: 10">
        <span class="dialog-title">{{ $t("all") }} {{ $t("notes") }}</span>
        <v-spacer />
        <v-icon small color="primary" @click="$emit('close')"
          >mdi-window-close</v-icon
        >
      </v-card-title>
      <div style=" background-color: white">
        <v-card
          style="border-radius: 0px"
          class="pa-3"
          v-for="(item, i) in notesFromRepo.filter((n) => {
            return (n.patientId = $route.params.userId);
          })"
          :key="i"
          flat
        >
          <!-- <v-textarea
            v-model="item.noteDescription"
            disabled
            style="border-radius: 12px"
            outlined
          /> -->
          <div class="d-flex px-1">
            <span
              >{{ item.userData.firstName }} {{ item.userData.lastName }}</span
            >
            <v-spacer />
            <span>{{
              item.noteTimestamp
                | dateformat("timestamp_med", item.userData.userTimezone)
            }}</span>
            
          </div>

          <span class="d-flex align-left px-2" style="font-size: 14px; font-weight: bold;">{{item.noteDescription}}</span>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["dialog"],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      notesFromRepo: (state) => state.appointments.notes,
    }),
  },
  mounted() {
    this.getAllNotes();
  },
  methods: {
    getAllNotes() {
      this.$store.dispatch("appointments/getAllNotes");
    },
  },
};
</script>