<template>
  <v-app>
    <v-row :style="`background-color: black; height: ${testHeight + 5}px`">
      <v-col class="" cols="12" xl="8" lg="7" md="12" sm="12">
        <v-card class="video-room-video" color="black" :height="testHeight">
          <v-col class="fill-height">
            <div v-if="callEnded">
              <span>Call Ended</span><br />
              <v-btn @click="reJoin"
                >Rejoin<v-icon small>mdi-arrow-left-bottom</v-icon></v-btn
              >
            </div>
            <div style="height: 100%" v-if="!callEnded">
              <v-row class="fill-height">
                <v-col cols="9" class="d-flex align-center justify-center">
                  <div style="border-radius: 8px; background-color: black">
                    <video
                      autoplay
                      :style="`border-radius: 8px; max-width: 100%`"
                      :height="testHeight - 30"
                      preload="none"
                      poster="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYniamX9xxvMlgmWIrZXmiueRb_Errvrqkaw&usqp=CAU"
                      id="patientvideo"
                    ></video>
                  </div>
                </v-col>
                <v-col cols="3" class="d-flex">
                  <div>
                    <video
                      autoplay
                      style="border-radius: 8px"
                      preload="none"
                      poster="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYniamX9xxvMlgmWIrZXmiueRb_Errvrqkaw&usqp=CAU"
                      width="100%"
                      muted
                      id="localvideo"
                    ></video>
                  </div>
                </v-col>
                <v-col
                  style="margin-top: -12%"
                  v-if="publishers.length !== 0"
                  cols="9"
                >
                  <v-btn fab class="mx-2" @click="stopAudio"
                    ><v-icon v-if="!mutedAudio">mdi-volume-high</v-icon
                    ><v-icon v-if="mutedAudio">mdi-volume-off</v-icon></v-btn
                  >
                  <v-btn fab class="mx-2" color="red" @click="endCall"
                    ><v-icon color="white">mdi-phone</v-icon></v-btn
                  >
                </v-col>
              </v-row>
              <!-- <v-btn
                id="videoButton"
                color="transparent"
                @click="stopVideo"
                ><v-icon v-if="!mutedVideo">mdi-camera</v-icon
                ><v-icon v-if="mutedVideo">mdi-camera-off</v-icon></v-btn
              > -->
            </div>
          </v-col>
        </v-card>
      </v-col>
      <v-col
        style="background-color: white"
        class="pt-7"
        cols="12"
        xl="4"
        lg="5"
        md="12"
        sm="12"
      >
        <v-card flat :style="`height: ${testHeight}px; overflow: auto;`">
          <v-progress-circular
            v-if="!detailedUser"
            indeterminate
            color="primary"
          />
          <PatientDetailsCard
            v-if="detailedUser"
            @device-edited="deviceEdited"
            :noChat="true"
            :video="true"
          />
          <div :style="`height: ${testHeight * 0.2}px;`"></div>
          <v-card flat class="px-6">
            <div class="d-flex my-2">
              <span class="d-flex align-left" style="font-size: 20px"
                >Notes</span
              >
              <v-spacer />
              <v-card
                flat
                color="primary"
                class="pa-1 d-flex align-center justify-center"
                @click="showNotesDialog = true"
              >
                <img height="20px" width="20px" :src="see_more_white" />
              </v-card>
            </div>
            <v-textarea
              v-model="notes"
              style="border-radius: 12px"
              label="Additional video call notes"
              outlined
            />
            <v-card-actions class="px-0">
              <v-spacer />
              <v-btn class="table-create-button" @click="addNote">
                <v-icon class="icon">mdi-plus</v-icon>
                <span>{{ $t("send") }} {{ $t("note") }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <ShowNotesDialog
      v-if="showNotesDialog"
      :dialog="showNotesDialog"
      @close="showNotesDialog = false"
    />
    <Alert :title="title" :status="snackStatus" :color="snackColor"></Alert>
  </v-app>
</template>

<script>
import { Janus } from "janus-gateway";
import { mapState } from "vuex";
import PatientDetailsCard from "@/views/Client/components/NewDesign/PatientDetailsCard.vue";
import Alert from "@/views/Client/components/Alert.vue";
import { see_more_white } from "@/assets/";
import ShowNotesDialog from "@/views/dialogs/ShowNotesDialog.vue";
var socketUrl = process.env.VUE_APP_JANUS_SERVER_URL;
// var socketUrl = "ws://janus.bridge-techs.com:8188/janus";
var vroomHandle = null;
var Vue = null;
// var videoStream = null;
export default {
  name: "App",
  components: {
    PatientDetailsCard,
    Alert,
    ShowNotesDialog,
    // NewChat,
  },
  data() {
    return {
      janus: null,
      notes: "",
      mutedAudio: false,
      mutedVideo: true,
      disabledVideo: false,
      disabledAudio: false,
      publishers: [],
      callEnded: false,
      snackStatus: false,
      title: "",
      snackColor: "",
      see_more_white: see_more_white,
      showNotesDialog: false,
    };
  },
  computed: {
    ...mapState({
      detailedUser: (state) => state.users.detailedUser,
    }),
    appointmentDetails() {
      return this.$route.params.details;
    },
    userDetails() {
      return this.detailedUser?.userData;
    },
    returnimage() {
      return "https://icons.veryicon.com/png/o/object/material-design-icons-1/account-outline.png";
    },
    fistPublisher() {
      return this.publishers[0]?.id;
    },
    testHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return window.innerHeight - 150;
        case "sm":
          return window.innerHeight - 60;
        case "md":
          return window.innerHeight - 60;
        case "lg":
          return window.innerHeight - 40;
        case "xl":
          return window.innerHeight - 40;
        default:
          return 0;
      }
    },
    alignment() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "col-center-alignment";
        case "sm":
          return "col-center-alignment";
        case "md":
          return "col-center-alignment";
        case "lg":
          return "col-left-alignment";
        case "xl":
          return "col-left-alignment";
        default:
          return "col-left-alignment";
      }
    },
  },
  async mounted() {
    if (this.$route.params.id && this.$route.params.userId) {
      await this.getUserById(this.$route.params.userId);
      this.init(parseInt(this.$route.params.id));
    }
  },
  destroyed() {
    vroomHandle.hangup();
    this.janus.destroy();
  },
  methods: {
    deviceEdited() {
      this.getUserById(this.$route.params.userId);
    },
    async getUserById(userId) {
      await this.$store.dispatch("users/getUserById", userId);
    },
    reJoin() {
      this.callEnded = false;
      this.mutedAudio = false;
      this.init(parseInt(this.$route.params.id));
    },
    async endCall() {
      await vroomHandle.hangup();
      await this.janus.destroy();
      this.callEnded = true;
    },
    stopAudio() {
      this.mutedAudio = !this.mutedAudio;
      if (this.mutedAudio) {
        vroomHandle.muteAudio();
      } else {
        vroomHandle.unmuteAudio();
      }
    },
    // stopVideo() {
    //   this.mutedVideo = !this.mutedVideo;
    //   if (this.mutedVideo) {
    //     document.getElementById("localvideo").srcObject = new MediaStream();
    //     vroomHandle.muteVideo();
    //   } else {
    //     document.getElementById("localvideo").srcObject = videoStream;
    //     vroomHandle.unmuteVideo();
    //   }
    // },
    async addNote() {
      this.snackStatus = false;
      if (this.notes === "" || this.notes === null) {
        return;
      }
      const body = {
        appointmentId: this.$route.params.appointmentId,
        noteDescription: this.notes,
        patientId: this.$route.params.userId,
      };
      await this.$store
        .dispatch("appointments/addAppointmentNote", body)
        .then(() => {
          this.notes = "";
          this.snackStatus = true;
          this.snackColor = "teal darken-2";
          this.title = "Note added succesfully!";
        })
        .catch(() => {
          this.notes = "";
          this.snackStatus = true;
          this.snackColor = "deep-orange darken-4";
          this.title = "Note could not be sent!";
        });
    },
    calculateAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString.split("T")[0]);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    init(roomId) {
      Vue = this;
      Janus.init({
        debug: "all",
        callback: () => {
          this.janus = new Janus({
            server: socketUrl,
            success: () => {
              this.janus.attach({
                plugin: "janus.plugin.videoroom",
                opaqueId: "videoroom-" + Janus.randomString(12),
                success: function (pluginHandle) {
                  vroomHandle = pluginHandle;
                  vroomHandle.send({
                    message: {
                      request: "exists",
                      room: roomId,
                    },
                    success: (res) => {
                      if (res.exists) {
                        let reg = Janus.randomString(12);
                        const register = {
                          request: "join",
                          room: roomId,
                          ptype: "publisher",
                          display: reg,
                        };
                        vroomHandle.send({ message: register });
                      } else {
                        vroomHandle.send({
                          message: {
                            request: "create",
                            room: roomId,
                            description: "Doctor",
                            publishers: 2,
                          },
                          success: () => {
                            let reg = Janus.randomString(12);
                            const register = {
                              request: "join",
                              room: roomId,
                              ptype: "publisher",
                              display: reg,
                            };
                            vroomHandle.send({ message: register });
                          },
                        });
                      }
                    },
                  });
                },
                onmessage: async (msg, jsep) => {
                  let event = msg["videoroom"];
                  if (event != undefined && event != null) {
                    if (event === "joined") {
                      let list = msg["publishers"];
                      this.publishers = list;
                      for (let f in list) {
                        let id = list[f]["id"];
                        let display = list[f]["display"];
                        let audio = list[f]["audio_codec"];
                        let video = list[f]["video_codec"];
                        this.newRemoteFeed(id, display, audio, video, roomId);
                      }
                      this.publishOwnFeed(true);
                    } else if (event === "event") {
                      if (
                        msg["publishers"] !== undefined &&
                        msg["publishers"] !== null
                      ) {
                        let list = msg["publishers"];
                        this.publishers = list;
                        for (let f in list) {
                          let id = list[f]["id"];
                          let display = list[f]["display"];
                          let audio = list[f]["audio_codec"];
                          let video = list[f]["video_codec"];
                          this.newRemoteFeed(id, display, audio, video, roomId);
                        }
                      } else if (
                        msg["leaving"] !== undefined &&
                        msg["leaving"] !== null
                      ) {
                        this.publishers = this.publishers.filter(function (
                          obj
                        ) {
                          return obj.id !== msg["leaving"];
                        });
                        if (this.publishers.length === 0) {
                          const video = document.getElementById("patientvideo");
                          var media = new MediaStream();
                          video.srcObject = media;
                        }
                      } else if (
                        msg["unpublished"] !== undefined &&
                        msg["unpublished"] !== null
                      ) {
                        // One of the publishers has unpublished?
                        if (msg["unpublished"] === "ok") {
                          vroomHandle.hangup();
                          return;
                        }
                      } else if (
                        msg["error"] !== undefined &&
                        msg["error"] !== null
                      ) {
                        if (msg["error_code"] === 426) {
                          // This is a "no such room" error: give a more meaningful description
                        } else if (msg["error_code"] === 432) {
                          // If max number of publishers is reached
                          vroomHandle.hangup();
                          this.janus.destroy();
                          this.callEnded = true;
                          alert(msg["error"]);
                          // This is a "no such room" error: give a more meaningful description
                        } else {
                          alert(msg["error"]);
                        }
                      }
                    }
                  }
                  if (jsep !== undefined && jsep !== null) {
                    vroomHandle.handleRemoteJsep({ jsep: jsep });
                  }
                },
                onlocalstream: function (stream) {
                  const video = document.getElementById("localvideo");
                  video.srcObject = stream;
                },
              });
            },
            error: () => {
              console.log(Vue);
              Vue.snackStatus = false;
              Vue.callEnded = true;
              Vue.snackStatus = true;
              Vue.title = "Error connecting to server, please try again!";
              Vue.snackColor = "deep-orange darken-4";
            },
          });
        },
      });
    },
    publishOwnFeed(useAudio) {
      vroomHandle.createOffer({
        media: {
          audioRecv: false,
          videoRecv: false,
          audioSend: useAudio,
          videoSend: true,
        },
        success: function (jsep) {
          Janus.debug("Got publisher SDP!");
          Janus.debug(jsep);
          const publish = {
            request: "publish",
            audio: useAudio,
            video: true,
          };
          vroomHandle.send({ message: publish, jsep: jsep });
        },
        error: function (error) {
          Janus.error("WebRTC error:", error);
          if (useAudio) {
            this.publishOwnFeed(false);
          }
        },
      });
    },
    newRemoteFeed(id, display, audio, video, roomId) {
      let remoteFeed = null;
      this.janus.attach({
        plugin: "janus.plugin.videoroom",
        opaqueId: "videoroom-" + Janus.randomString(12),
        success: function (pluginHandle) {
          remoteFeed = pluginHandle;
          let subscribe = {
            request: "join",
            room: roomId,
            ptype: "subscriber",
            feed: id,
          };
          remoteFeed.videoCodec = video;
          remoteFeed.send({ message: subscribe });
        },
        onmessage: function (msg, jsep) {
          if (jsep) {
            remoteFeed.createAnswer({
              jsep: jsep,
              media: { audioSend: true, videoSend: true }, // We want recvonly audio/video
              success: function (jsep) {
                let body = { request: "start", room: roomId };
                remoteFeed.send({ message: body, jsep: jsep });
              },
              error: function (error) {
                console.error("WebRTC error:", error);
              },
            });
          }
        },
        onremotestream: function (stream) {
          const video = document.getElementById("patientvideo");
          video.srcObject = stream;
        },
      });
    },
  },
};
</script>
<style scoped>
#container {
  width: 100px;
  height: 100px;
  position: relative;
}
#navi,
#infoi {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
}
#infoi {
  z-index: 10;
}
</style>
